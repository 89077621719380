<template>
  <div>
    <v-list-item three-line>
      <v-list-item-content>
        <span class="display-1 text--primary d-flex align-center align-content-center">
          {{ translate(documentType.language_key) }}
          <v-spacer />
          <v-btn text color="white" @click="backToFolders">
            <v-icon left color="black">
              mdi-arrow-left
            </v-icon>
            <strong class="black--text">{{ $t('general.buttons.back') }}</strong>
          </v-btn>
        </span>
      </v-list-item-content>
    </v-list-item>
    <v-data-table
      v-if="toggleGrid === 0"
      :headers="headers"
      :items="documents"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalFromServer"
      :show-select="false"
      :no-data-text="$t('general.ui.not_data_found')"
      :loading-text="$t('general.notification.loadData')"
      :footer-props="{
        ...rowsPerPageItemsOptions,
        itemsPerPageText: $t('general.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
      }"
    >
      <template v-slot:top>
        <v-row class="d-flex justify-space-between align-center mb-1">
          <v-col lg="4" md="4" sm="12" xl="4">
            <v-text-field
              onkeypress="return (event.charCode != 34)"
              v-on:keyup.86="replace"
              v-on:keyup.ctrl.86="replace"
              dense
              filled
              rounded
              color="primary"
              v-model="search"
              :clearable="true"
              @keypress.enter="searchForText"
              @click:clear="searchAll"
              @click:append="searchForText"
              append-icon="mdi-magnify"
              :label="$t('general.titles.filter')"
              single-line
              hide-details
            />
          </v-col>
          <v-col cols="4" class="d-flex justify-start align-center align-content-center">
            <v-btn-toggle v-model="toggleGrid" dense class="mx-4">
              <v-btn>
                <v-icon>mdi-view-list-outline</v-icon>
              </v-btn>

              <v-btn>
                <v-icon>mdi-view-module-outline</v-icon>
              </v-btn>
            </v-btn-toggle>
            <v-autocomplete
              outlined
              hide-details
              dense
              ref="documentDescriptionFilters"
              :label="$t('documents.fields.description')"
              :no-data-text="$t('general.fields.noDataAvailable')"
              :loading="loadingDescription"
              class="required"
              return-object
              clearable
              item-color="primary"
              color="primary"
              item-value="id"
              multiple
              item-text="document_description_name"
              :items="documentDescriptions"
              v-model="descriptionsSelected"
              @change="getDocumentsPaginatedByCompany()"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index === 0">
                  <span>{{ item.document_description_name | truncate(50) }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text caption"
                >(+{{
                    descriptionsSelected.length - 1
                  }}
                      {{ $t('general.titles.others') }})</span
                >
              </template>
              <template v-slot:item="{ item, attrs }">
                <div style="max-width: 360px">
                  <v-checkbox
                    :input-value="attrs.inputValue"
                    color="primary"
                    class="pt-2 pb-2 mt-0"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <read-more
                        :classes="'mb-0'"
                        :text="translate(item.language_key)"
                        :max-chars="80"
                        :font-size="14"
                      />
                    </template>
                  </v-checkbox>
                </div>
              </template>
              <template v-slot:prepend-item>
                <v-checkbox
                  v-model="checkboxState.document_description"
                  color="primary"
                  class="pt-2 pb-2 mt-0 pl-4"
                  :hide-details="true"
                  @change="
                          descriptionsSelected = selectAll(
                            'document_description',
                            descriptionsSelected,
                            $refs.documentDescriptionFilters.filteredItems,
                            checkboxState.document_description,
                            'id'
                          )
                        "
                >
                  <template v-slot:label>
                    <v-btn small color="primary" x-small text>
                      {{ $t('general.titles.selectAll') }}
                    </v-btn>
                  </template>
                </v-checkbox>
                <v-divider class="mb-2" />
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.expirated="{ item }">
        <span class="dot-active" v-if="validateDate(item.validade)">

        </span>
        <span class="dot-expired" v-else>

        </span>
      </template>

      <template v-slot:item.extension="{ item }">
        <v-img width="28" :src="getExtensionIcon(item.file)" />
      </template>

      <template v-slot:item.season="{ item }">
        {{  item.season ? translate(item.season.languageKey) : '-' }}
      </template>

      <template v-slot:item.emission="{ item }">
        <span>{{ item.emission }}</span>
      </template>
      <template v-slot:item.validade="{ item }">
        <span v-if="item.validade">{{ item.validade }}</span>
        <span v-else>
          <v-chip class="ml-3" small disabled>
            N/A
          </v-chip>
        </span>
      </template>

      <template v-slot:item.observations="{ item }">
        <read-more
          v-if="item.observations"
          :classes="'pa-0 ma-0'"
          :text="item.observations"
          :max-chars="100"
          :font-size="14"
        />
        <span v-else>
          <v-chip small disabled>
            N/A
          </v-chip>
        </span>
      </template>

      <template v-slot:item.isPublic="{ item }">
        <span v-if="item.isPublic">
          <v-chip small>
            {{ $t('documents.labels.options.visible') }}

          </v-chip>
        </span>
        <span v-else>
          <v-chip small disabled>
            {{ $t('documents.labels.options.notVisible') }}
          </v-chip>
        </span>
      </template>

      <template v-slot:item.active="{ item }">
        <v-icon
          small
          class="mr-4"
          color="success"
          v-if="item.active"
        >
          mdi-checkbox-marked-circle-outline
        </v-icon>
        <v-icon
          small
          class="mr-4"
          color="disabled"
          v-else
        >
          mdi-checkbox-marked-circle-outline
        </v-icon>
      </template>

      <template v-slot:item.file='{ item }'>
        <v-chip class='ma-1' small>{{ item.file }}</v-chip>
      </template>

      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="neuter"
              small
              class="mr-2"
              @click="showDocument(item)"
            >
              mdi-eye-outline
            </v-icon>
          </template>
          <span>
                {{ $t('general.buttons.show') }}
                <span style="font-size: 12px; color: #aaaaaa">
                  {{ $t('menu.documents') }}
                </span>
              </span>
        </v-tooltip>

        <!-- Dialog MORE DETAILS -->
        <MoreDetailsComponent :item="item"
                              :dialog="showDocumentDialog[item.id]"
                              @close:dialog="$set(showDocumentDialog, item.id, $event)"
                              @loading:download="$set(loadingDownloadDocument, $event.id, $event.loading)"
        />
      </template>
    </v-data-table>
    <v-data-iterator
      class="mb-12 pb-12"
      v-else
      :items="documents"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalFromServer"
      :show-select="false"
      :no-data-text="$t('general.ui.not_data_found')"
      :loading-text="$t('general.notification.loadData')"
      :footer-props="{
        ...rowsPerPageItemsOptions,
        itemsPerPageText: $t('general.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
      }"
    >
      <template v-slot:header>
        <v-row class="d-flex justify-space-between align-center mb-1">
          <v-col lg="4" md="4" sm="12" xl="4">
            <v-text-field
              onkeypress="return (event.charCode != 34)"
              v-on:keyup.86="replace"
              v-on:keyup.ctrl.86="replace"
              dense
              filled
              rounded
              color="primary"
              v-model="search"
              :clearable="true"
              @keypress.enter="searchForText"
              @click:clear="searchAll"
              @click:append="searchForText"
              append-icon="mdi-magnify"
              :label="$t('general.titles.filter')"
              single-line
              hide-details
            />
          </v-col>
          <v-col cols="4" class="d-flex justify-start align-center align-content-center">
            <v-btn-toggle v-model="toggleGrid" dense class="mx-4">
              <v-btn>
                <v-icon>mdi-view-list-outline</v-icon>
              </v-btn>

              <v-btn>
                <v-icon>mdi-view-module-outline</v-icon>
              </v-btn>
            </v-btn-toggle>
            <v-autocomplete
              outlined
              hide-details
              dense
              ref="documentDescriptionFilters"
              :label="$t('documents.fields.description')"
              :no-data-text="$t('general.fields.noDataAvailable')"
              :loading="loadingDescription"
              class="required"
              return-object
              clearable
              item-color="primary"
              color="primary"
              item-value="id"
              multiple
              item-text="document_description_name"
              :items="documentDescriptions"
              v-model="descriptionsSelected"
              @change="getDocumentsPaginatedByCompany()"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index === 0">
                  <span>{{ item.document_description_name | truncate(50) }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text caption"
                >(+{{
                    descriptionsSelected.length - 1
                  }}
                      {{ $t('general.titles.others') }})</span
                >
              </template>
              <template v-slot:item="{ item, attrs }">
                <div style="max-width: 360px">
                  <v-checkbox
                    :input-value="attrs.inputValue"
                    color="primary"
                    class="pt-2 pb-2 mt-0"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <read-more
                        :classes="'mb-0'"
                        :text="translate(item.language_key)"
                        :max-chars="80"
                        :font-size="14"
                      />
                    </template>
                  </v-checkbox>
                </div>
              </template>
              <template v-slot:prepend-item>
                <v-checkbox
                  v-model="checkboxState.document_description"
                  color="primary"
                  class="pt-2 pb-2 mt-0 pl-4"
                  :hide-details="true"
                  @change="
                          descriptionsSelected = selectAll(
                            'document_description',
                            descriptionsSelected,
                            $refs.documentDescriptionFilters.filteredItems,
                            checkboxState.document_description,
                            'id'
                          )
                        "
                >
                  <template v-slot:label>
                    <v-btn small color="primary" x-small text>
                      {{ $t('general.titles.selectAll') }}
                    </v-btn>
                  </template>
                </v-checkbox>
                <v-divider class="mb-2" />
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </template>
      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="(item, index) in props.items"
            :key="index"
            cols="3"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card outlined>
              <!-- Sección para mostrar la vista previa -->
              <div class="preview-container" v-if="['jpg', 'png', 'jpeg'].includes(item.file)">
                <v-img height="180px" max-height="100%" :src="getPreviewImage(item)" aspect-ratio="16/9" />
              </div>
              <div class="preview-container" v-else-if="['pdf'].includes(item.file)">
                <PreviewPdfComponent :pdf-url="item.s3_url" />
              </div>
              <div class="preview-container" v-else-if="['zip'].includes(item.file)">
                <v-img height="180px" max-height="100%" :src="require('@/assets/img/files/zip.png')" aspect-ratio="16/9" />
              </div>
              <div v-else-if="['xlsx', 'csv', 'xls'].includes(item.file)" class="preview-container">
                <v-img height="180px" max-height="100%" :src="require('@/assets/img/files/excel.png')" aspect-ratio="16/9" />
              </div>
              <div v-else-if="['doc', 'docx'].includes(item.file)" class="preview-container">
                <v-img height="180px" max-height="100%" :src="require('@/assets/img/files/word.png')" aspect-ratio="16/9" />
              </div>
              <div class="preview-container d-flex justify-center align-center" v-else>
                <v-chip small disabled>
                  Pré-visualização não disponível
                </v-chip>
              </div>

              <v-card-subtitle class="mx-3 d-flex flex-column">
                <read-more
                  :classes="'mb-1'"
                  :font-size="14"
                  :max-chars="80"
                  :text="item.description_name || ''"
                />
                <span class="mb-1" v-if="item.validade">
                  <strong>{{$t('documents.fields.expiration_date')}}:</strong> {{item.validade}}
                </span>
                <span class="mb-1" v-else>
                  -
                </span>
                <span class="mb-1" v-if="item.isPublic">
                  <strong>{{$t('documents.fields.public')}}:</strong>
                  <v-chip small>
                    {{ $t('documents.labels.options.visible') }}
                  </v-chip>
                </span>
                <span class="mb-1" v-else>
                  <strong>{{$t('documents.fields.public')}}:</strong>
                  <v-chip small disabled>
                    {{ $t('documents.labels.options.notVisible') }}
                  </v-chip>
                </span>
              </v-card-subtitle>

              <v-card-actions class="mx-3">
                <v-btn
                  v-if="['jpg', 'png', 'jpeg', 'pdf'].includes(item.file)"
                  text
                  small
                  fab
                >
                  <v-icon @click="printDoc(item)"
                          color="primary"
                  >
                    mdi-printer
                  </v-icon>
                </v-btn>
                <v-spacer />
                <v-btn
                  text
                  @click="downloadDocShow(item)"
                  :loading="loadingDownloadDocument[item.id]"
                >
                  <template v-slot:loader>
                    <v-progress-circular
                      style="position: absolute; margin-left: -3px; height: 15px"
                      color="secondary accent-4"
                      indeterminate
                      rounded
                    />
                  </template>
                  {{ $t('general.buttons.download') }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="showDocument(item)"
                >
                  {{ $t('general.buttons.show') }}
                </v-btn>

                <!-- Dialog MORE DETAILS -->
                <MoreDetailsComponent :item="item"
                                      :dialog="showDocumentDialog[item.id]"
                                      @close:dialog="$set(showDocumentDialog, item.id, $event)"
                                      @loading:download="$set(loadingDownloadDocument, $event.id, $event.loading)"
                />
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
import _ from "lodash";
import i18n from "@/plugins/i18n";
import {mapActions, mapGetters} from "vuex";
import LanguageService from "@/services/LanguajeService";
import moment from "moment/moment";
import pdf from "vue-pdf";
import printJs from "print-js";
import DocumentsService from "@/services/DocumentsService";
import ReadMore from "@/components/ReadMoreComponent.vue";
import MoreDetailsComponent from "@/module/documents/components/MoreDetailsComponent.vue";
import PreviewPdfComponent
  from "@/module/configuration/storage/components/tabs/items/DocumentView/PreviewPdfComponent.vue";

const PROFILE = JSON.parse(localStorage.getItem('profile'));

export default {
  name: "DocumentComponent",
  components: {PreviewPdfComponent, MoreDetailsComponent, ReadMore, pdf},
  props: {
    documentType: {
      type: Object,
      required: true,
    },
    storage: {
      type: Object,
      required: true,
    }
  },

  data: () => ({
    options: {},
    toggleGrid: 0,
    loading: false,
    loadingDownloadDocument: {},
    showDocumentDialog: {},
    search: '',
    rowsPerPageItemsOptions: {
      itemsPerPageOptions: [10, 20, 50, 100, 200],
    },
    filters: {
      pageableDTO: {
        page: 1,
        size: 10,
        sortBy: 'document_date',
        direction: 'desc',
      },
      stringFilter: null,
      document_type_ids: [],
      storage_devices_ids: [],
      document_description_ids: []
    },
    loadingDescription: false,
    descriptionsSelected: null,
    checkboxState: {
      document_description: false,
    },
    keys: [
      'file',
      'name',
      'description_name',
      'emission',
      'validade',
    ],
    sortBy: 'name',
  }),

  watch: {
    options: {
      async handler() {
        this.optionsTable = this.options
        await this.searchText()
      },
      deep: true,
    },
  },

  async created() {
    await this.documentDescriptionFiltered()
  },

  computed: {
    ...mapGetters({
      totalFromServer: 'documents/filterDocumentsTotalCount',
    }),
    documents: {
      get() {
        return this.$store.getters['documents/filterDocuments']
      },
      set(val) {
        this.$store.commit('documents/SET_FILTERS_DOCUMENTS_LIST', val)
      },
    },
    documentDescriptions: {
      get() {
        return this.$store.getters[
          "document_description/documentDescriptions"
          ]
      },
      set(val) {
        this.$store.commit(
          "document_description/SET_DOCUMENT_DESCRIPTION_LIST",
          val
        )
      },
    },
    headers() {
      return [
        {
          text: "",
          value: "expirated",
          sortable: false,
        },
        {
          text: i18n.t('documents.fields.description'),
          value: 'description_name',
          type: 'text',
          width: 250,
          sortable: false,
        },
        {
          text: i18n.t('documents.fields.document_date'),
          value: 'emission',
          val: 'document_date',
          type: 'text',
          width: 130,
          sortable: true,
        },
        {
          text: i18n.t('documents.fields.expiration_date'),
          value: 'validade',
          val: 'expiration_date',
          type: 'text',
          width: 130,
          sortable: true,
        },
        {
          text: i18n.t('documents.fields.season'),
          value: 'season',
          type: 'text',
          sortable: false,
        },
        {
          text: i18n.t('documents.fields.observations'),
          value: 'observations',
          type: 'text',
          width: 200,
          sortable: false,
        },
        {
          text: i18n.t('documents.fields.public'),
          value: 'isPublic',
          val: 'isPublic',
          type: 'text',
          width: 120,
          sortable: true,
        },
        {
          text: i18n.t('general.fields.active'),
          value: 'active',
          type: 'text',
          width: 100,
          sortable: true,
        },
        {
          text: i18n.t('general.fields.action'),
          value: 'action',
          width: 160,
          sortable: false,
          align: 'center'
        },
      ]
    },
  },

  methods: {
    ...mapActions({
      fetchListDocumentsFiltered: 'documents/fetchListDocumentsFiltered',
      fetchListDocumentsBySearch: 'documents/fetchListDocumentsBySearch',
      fetchListDocumentDescriptionsByType:
        "document_description/fetchListDocumentDescriptionsByType",
      fetchDocumentById: 'documents/fetchDocumentById',
      fetchListVersions: 'documents/fetchListVersions'
    }),
    getPreviewImage(item) {
      if (['jpg', 'jpeg', 'png'].includes(item.file.toLowerCase())) {
        return item.s3_url;
      }
      return require('@/assets/img/files/image.png');
    },
    async printDoc(item) {
      await this.$root.$emit('print:document', item);
    },
    async downloadDocShow(item) {
      await this.$root.$emit('download:document', item)
    },
    selectAll(type, length, value, checkbox, idField) {
      if( checkbox === false ){
        this.$nextTick(function(){
          switch (type) {
            case 'document_description':
              this.checkboxState.document_description = false
              break
          }
        })
        return [];
      }

      let aux = [];
      if( length != null ) {
        if (typeof length[0] === 'object') {
          _.forEach(length, (object) => {
            if (!_.find(value, (o) => {
              return o[idField] === object[idField]
            }))
              value.push(object)
          })
        } else {
          _.forEach(length, (text) => {
            if (!value.includes(text))
              value.push(text)
          })
        }

        aux = value;
      }else{
        aux = value;
      }
      return aux;
    },
    backToFolders() {
      this.$emit('document:details', false)
      this.$router.push({ name: 'storage-management', params: { action: 'show' } })
      this.documents = []
    },
    async documentDescriptionFiltered() {
      if (this.documentType) {
        this.loadingDescription = true
        await this.fetchListDocumentDescriptionsByType([
          PROFILE.company_id,
          [this.documentType.id],
          this.$toast,
        ]).finally(() => {
          this.loadingDescription = false
        })
      } else {
        this.loadingDescription = null
      }
    },
    async showDocument(item) {
      this.loading = true
      await this.fetchDocumentById([item.id, this.$toast]).finally(() => {
        this.$set(this.showDocumentDialog, item.id, true);
        this.loading = false
      })
    },
    getExtensionIcon(ext) {
      switch (ext.toLowerCase()) {
        case 'pdf':
          return require('@/assets/icons/files/icons8-pdf-96.png');
        case 'jpg':
        case 'jpeg':
          return require('@/assets/icons/files/icons8-jpg-96.png');
        case 'png':
          return require('@/assets/icons/files/icons8-png-96.png');
        case 'doc':
        case 'docx':
          return require('@/assets/icons/files/icons8-doc-96.png');
        case 'xls':
        case 'xlsx':
          return require('@/assets/icons/files/icons8-csv-96.png');
        case 'zip':
          return require('@/assets/icons/files/icons8-7zip-96.png');
        default:
          return require('@/assets/icons/files/icons8-arquivo-96.png');
      }
    },
    validateDate(date) {
      if (date) {
        date = date.split('/')
        date = moment(`${date[2]}-${date[1]}-${date[0]}`).unix()
        const current = moment().unix()

        return date > current
      } else {
        return true
      }
    },
    translate(key, uppercase) {
      return LanguageService.getKey3(key)
    },
    async searchAll() {
      this.options.page = 1
      this.filters.stringFilter = '';
      await this.getDocumentsPaginatedByCompany()
    },
    async searchForText() {
      this.options.page = 1
      await this.searchText()
    },
    async searchText() {
      this.loading = true

      this.filters.company_id = PROFILE.company_id
      this.filters.document_type_ids = [this.documentType.id]
      this.filters.storage_devices_ids = [this.storage.storageDeviceId]

      let direction =
        this.options.sortDesc.length === 0 || this.options.sortDesc[0]
          ? 'asc'
          : 'desc'
      let sort =
        this.options.sortBy.length === 0
          ? 'document_date'
          : _.filter(this.headers, { value: this.options.sortBy[0] })[0].val

      this.filters.pageableDTO = {
        page: this.options.page - 1,
        size: this.options.itemsPerPage,
        sortBy: sort ? sort : 'document_date',
        direction: direction,
      }

      if (this.search && this.search !== '') {
        this.filters.stringFilter = this.search

        await this.fetchListDocumentsFiltered([this.filters, this.$toast])
      } else {
        await this.getDocumentsPaginatedByCompany()
      }

      this.loading = false
    },
    async getDocumentsPaginatedByCompany() {
      this.loading = true
      let direction =
        this.options.sortDesc.length === 0 || this.options.sortDesc[0]
          ? 'asc'
          : 'desc'
      let sort =
        this.options.sortBy.length === 0
          ? 'document_date'
          : _.filter(this.headers, { value: this.options.sortBy[0] })[0].val

      this.filters.pageableDTO = {
        page: this.options.page - 1,
        size: this.options.itemsPerPage,
        sortBy: sort ? sort : 'document_date',
        direction: direction,
      }

      this.filters.document_description_ids = _.map(this.descriptionsSelected, i => i.id)

      this.filters.company_id = PROFILE.company_id

      await this.fetchListDocumentsFiltered([this.filters, this.$toast]).finally(
        () => {
          this.loading = false
        }
      )
    },
    replace(event) {
      event.currentTarget.value = event.currentTarget.value
        .toString()
        .replaceAll('"', '')
      this.search = event.currentTarget.value.toString().replaceAll('"', '')
    },
  }
};
</script>

<style scoped>
.dot-active {
  height: 42px;
  width: 8px;
  background-color: rgb(3,172,140);
  background-color: rgba(3,172,140,1);
  display: inline-block;
}
.dot-expired {
  height: 42px;
  width: 8px;
  background-color: rgb(232,59,54);
  background-color: rgba(232,59,54,1);
  display: inline-block;
}
.v-chip.v-size--small,
.v-chip.v-size--x-small {
  border-radius: 0.3em !important;
}

.preview-container {
  height: 180px !important;
  object-fit: contain;
  max-width: 100%;

  & :deep(.vue-pdf-embed__page) {
    height: 180px;
    object-fit: contain;
    overflow: hidden;
  }
}
</style>